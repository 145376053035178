import request from '@/utils/request.js';
/**
 * 列表
 * @returns {Promise}
 */
export function getList(data) {
  return request({
    url: '/traffic-construction/environmentalFacilities/page',
    method: 'post',
    data
  });
}
/* 删除记录
 * @returns {Promise}
 */
export function deleteRecord(data) {
  return request({
    url: '/traffic-construction/environmentalFacilities/delete',
    method: 'post',
    data
  });
}
/* 新增或修改
 * @returns {Promise}
 */
export function addOrEdit(data) {
    return request({
      url: '/traffic-construction/environmentalFacilities/addOrEdit',
      method: 'post',
      data
    });
  }
/* 详情
 * @returns {Promise}
 */
export function detail(params) {
  return request({
    url: '/traffic-construction/environmentalFacilities/detail',
    method: 'get',
    params
  });
}
/* 详情
 * @returns {Promise}
 */
export function exportlist(data) {
  return request({
    url: '/traffic-construction/environmentalFacilities/export',
    method: 'post',
    data,
    responseType: 'blob'
  });
}