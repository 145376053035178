<template lang="">
  <div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="show"
      :close-on-click-modal="false"
      @close="handelClose"
      width="960px"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="150px"
        v-loading="dialogLoading"
        class="form-box"
      >
        <el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="所属项目部" prop="projectDepartment" :required="!isRead">
                  <el-select v-model="ruleForm.projectDepartment" :disabled="isRead" placeholder="请选择所属项目部" style="width:100%">
                    <el-option v-for="item in orgList" :key="item.orgId" :label="item.orgName" :value="item.orgId">
                    </el-option>
                  </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="环水保设施名称" prop="name" :required="!isRead">
                <el-input v-model="ruleForm.name" type="text" :maxlength="20" placeholder="请输入" :disabled="isRead"> </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="所属工点" prop="location" :required="!isRead">
                <el-input
                  v-model="ruleForm.location"
                  placeholder="请输入"
                  :maxlength="20"
                  :disabled="isRead"
                  type="text"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="设施投入量" prop="num" :required="!isRead">
                <el-input
                  v-model="ruleForm.num"
                  placeholder="请输入"
                  :maxlength="20"
                  :disabled="isRead"
                  oninput="value=value.replace(/[^\d]/g,'')"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="单位" prop="unit" :required="!isRead">
                <el-input v-model="ruleForm.unit" :maxlength="20" placeholder="请输入" :disabled="isRead"> </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="使用范围" prop="scope" :required="!isRead">
                <el-input
                  v-model="ruleForm.scope"
                  placeholder="请输入"
                  :maxlength="20"
                  :disabled="isRead"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="备注" prop="remark">
                <el-input
                  v-model="ruleForm.remark"
                  placeholder="请输入"
                  :maxlength="20"
                  :disabled="isRead"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="现场图片" prop="fileIds">
                <div v-if="!isRead">
                <UploadMultipleImage v-if="!isRead" :limit="5" :default-list="defaultImage"  @get-file-data="getImageData"></UploadMultipleImage>
                </div>
                <div v-else>
                  <div class="image-box" v-if="defaultImage.length">
                    <img :src="imageSingle.url" alt="图片" v-for="imageSingle in defaultImage" :key="imageSingle.fileId" @click="handlePictureCardPreview(imageSingle.url)">
                  </div>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <span v-if="isRead">
          <el-button type="danger" @click="deleteInfo" :loading="btnLoading">删 除</el-button>
          <el-button type="primary" @click="updataInfo">修 改</el-button>
        </span>
        <span v-else="isRead">
          <el-button @click="handelChanel">取 消</el-button>
          <el-button type="primary" @click="handelSure" :loading="btnLoading">确 定</el-button>
        </span>
      </div>
    </el-dialog>
    <!-- 预览图片 -->
    <el-dialog :visible.sync="imageVisible">
      <img width="100%" :src="dialogImageUrl" alt="预览大图" />
    </el-dialog>
  </div>
</template>
<script>
import { getDataRangByNature } from '@/api/general-user/env/common';
import { deleteRecord, addOrEdit } from '@/api/general-user/env/protective-facility';
import { validateRules } from './validateRules.js';
import UploadMultipleImage from 'components/Upload/UploadMultipleImage.vue';
import { downloadFile } from '@/api/general-user/internal-data.js';
import { exportFun } from '@/utils/export.js';

export default {
  mixins: [validateRules],
  components: { UploadMultipleImage },
  props: {
    dialogTitle: {
      //标题
      type: String,
      required: true,
      default: ''
    },
    dialogVisible: {
      //显隐
      type: Boolean,
      required: true,
      default: false
    },
    /* 传递的数据 */
    info: {
      type: Object,
      required: true,
      default: {}
    }
  },
  data() {
    return {
      /* 显隐 */
      show: this.dialogVisible,
      /* 显示数据 */
      ruleForm: {},
      /* 是否是查看详情 */
      isRead: false,
      /* 机构数 */
      orgList: [],
      /* 确认按钮状态 */
      btnLoading: false,
      defaultList: [],
      /* 自身机构 */
      selfOrg: {},
      dialogLoading: false,
      /* 时间选取今天以前 */
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      id: '',
      defaultImage:[],
      imageVisible:false,
      dialogImageUrl:'',
    };
  },
  methods: {
    /* 父级传递的值 */
    initFormDetail(val) {
      this.resetRuleform();
      this.ruleForm = val;
      this.id = val.id; //该数据ID
      //这里图片上传组件的回显
      if(val.sysFileInfos.length){
        val.sysFileInfos.forEach(item=>{
          let obj={
            name:item.fileOriginName,
            url:item.filePath,
            fileId:item.fileId
          }
          this.defaultImage.push(obj);
        })
      }
    },
    /* 回传图片 */
    getImageData(fileId){//新增或者编辑
      this.ruleForm.pictureIds=fileId.join(',');
    },
    /*预览图片  */
    handlePictureCardPreview(imageUrl){
      this.dialogImageUrl=imageUrl;
      this.imageVisible = true;
    },
    /* 退出重置 */
    handelClose() {
      this.defaultList = [];
      this.resetRuleform();
      this.$refs.ruleForm.resetFields();
      this.handelChanel();
    },
    /* 关闭弹出框 */
    handelChanel() {
      this.$emit('update:dialogVisible', false);
    },
    /* 新增或修改 */
    handelSure() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.dialogTitle === '新增') {
            this.add();
          } else if (this.dialogTitle === '修改') {
            this.Edit();
          }
        } else {
          return false;
        }
      }); //新增
    },
    /* 新增方法 */
    add() {
      this.btnLoading = true;
      addOrEdit(this.ruleForm)
        .then(res => {
            this.btnLoading = false;
            this.$message.success('新增成功');
            this.$emit('update:dialogVisible', false);
            this.$emit('handel-success', [true, false]);
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    deleteInfo() {
      this.$confirm('请确认是否删除该记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        this.deleteRecord();
      });
    },
    /* 删除 */
    deleteRecord() {
      deleteRecord({ id: this.id }).then(res => {
          this.$message.success('删除成功');
          this.$emit('update:dialogVisible', false);
          this.$emit('handel-success', [false, true]);
      });
    },
    /* 修改 */
    updataInfo() {
      this.dialogLoading = true;
      setTimeout(() => {
        this.dialogLoading = false;
      }, 500);
      this.$emit('update:dialogTitle', '修改');
      this.isRead = false;
    },
    /* 修改接口调用*/
    Edit() {
      // this.resetRuleform(this.ruleForm);
      this.ruleForm.id = this.id;
      this.btnLoading = true;
      addOrEdit(this.ruleForm)
        .then(res => {
            this.btnLoading = false;
            this.$message.success('修改成功');
            this.$emit('update:dialogVisible', false);
            this.$emit('handel-success', [false, false]);
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    /* 重置方法*/
    resetRuleform() {
      this.ruleForm = {
        ruleForm:"",
        name:"",
        num:"",
        projectDepartment:"",
        remark:"",
        scope:"",
        unit:"",
        pictureIds:""
      };
      this.defaultImage=[];
      this.imageVisible=false;
    },
    /* 获取机构数 */
    getDataRangByNature() {
      getDataRangByNature().then(res => {
          this.orgList=res.data;
      });
    },
    /* 上传附件处理 */
    getFileData(fileIdList) {
      this.ruleForm.fileIds = fileIdList.join(',');
    },
  },
  created() {
    this.getDataRangByNature();
    this.resetRuleform();
  },
  watch: {
    dialogTitle(val) {
     if (val.includes('新增')) {
        this.resetRuleform();
      }
    },
    dialogVisible(val) {
      this.show = val;
    },
    isRead(val) {
      this.isRead = val;
    }
  }
};
</script>
<style lang="less" scoped>
.image-box{
  display: flex;
  img{
    width:80px;
    height:80px;
    display:block;
    margin-right: 6px;
    cursor: pointer;
  }
}</style>
