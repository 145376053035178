<template lang="">
  <div>
    <!-- 列表搜索 -->
    <el-form :inline="true" :model="pages" class="demo-form-inline">
      <el-form-item label="设施名称">
        <el-input v-model="pages.name" placeholder="请输入设施名称" clearable style="width:250px"></el-input>
      </el-form-item>
      <el-form-item label="所属项目部">
        <el-select v-model="pages.projectDepartmentIds" placeholder="请选择所属项目部" clearable multiple collapse-tags>
            <el-option v-for="item in orgList" :key="item.orgId" :label="item.orgName" :value="item.orgId"></el-option>
          </el-select>
      </el-form-item>
      <el-form-item class="btn">
        <el-button type="primary" @click="onSubmit">查询</el-button>
        <el-button @click="resetInput">重置</el-button>
      </el-form-item>
    </el-form>
    <!-- 功能区按钮 -->
    <div>
      <el-button v-if="$check('env-protective-facility-manage-add')" type="primary" @click="addInfo" icon="el-icon-plus">新增环保设施</el-button>
      <el-button
        v-if="$check('env-protective-facility-manage-export')"
        class="exportbtn"
        :disabled="!isChoose"
        v-loading.fullscreen="exportLoading"
        element-loading-text="拼命导出中"
        element-loading-background="rgba(0, 0, 0, 0.1)"
        @click="exportData"
        >导出</el-button>
    </div>
    <!-- 显示列表 -->
    <el-table
      ref="multipleTable"
      :data="tableData"
      stripe
      style="width: 100%"
      v-loading="tabelLoading"
      @selection-change="handleSelectionChange"
      max-height="550"
    >
      <el-table-column type="selection" width="55" align="center" fixed> </el-table-column>
      <el-table-column label="序号" align="center" width="55" fixed>
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="projectDepartmentName" label="所属项目部" align="center" width="180" fixed sortable> </el-table-column>
      <el-table-column prop="name" label="环水保设施名称" align="center" width="150" sortable> </el-table-column>
      <el-table-column prop="location" label="所属工点" align="center" width="150" show-overflow-tooltip sortable> </el-table-column>
      <el-table-column prop="num" label="设施投入量" align="center" show-overflow-tooltip sortable> </el-table-column>
      <el-table-column prop="unit" label="单位" align="center" width="150" show-overflow-tooltip sortable> </el-table-column>
      <el-table-column prop="scope" label="使用范围" align="center" show-overflow-tooltip sortable> </el-table-column>
      <el-table-column label="操作" align="center" width="150" fixed="right">
        <template slot-scope="scope">
          <span v-if="$check('env-protective-facility-manage-edit')" class="action-btn" @click="handleDetails(scope.row)">查看详情</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-changee="handleCurrentChange"
      :current-page="pages.pageNo"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pages.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!-- 详情、新增、修改框 -->
    <list-dialog
      ref="formRef"
      :dialog-title.sync="dialogTitle"
      :dialog-visible.sync="dialogVisible"
      :info="dialogData"
      @handel-success="handelSuccess"
    ></list-dialog>
  </div>
</template>
<script>
import { getList,detail,exportlist } from '@/api/general-user/env/protective-facility';
import { getDataRangByNature } from '@/api/general-user/env/common';
import listDialog from './listDialog.vue';
import { cloneDeep } from 'lodash';
import { exportFun } from '@/utils/export.js';
export default {
  components: { listDialog },
  data() {
    return {
      /* 机构数 */
      orgList: [],
      /* 数据 */
      tableData:[],
      total:0,
      tabelLoading: false,
      /* 分页、搜索栏 */
      pages: {
        pageSize: 10,
        pageNo: 1,
        name:"",
        projectDepartmentIds:[],
      },
      trainDate: [],
      /* 回显数据 */
      dialogVisible: false,
      dialogTitle: '',
      dialogData: {},
      /* 导出按钮状态 */
      isChoose: false,
      exportLoading: false,
      /* 导出数据ID */
      ids: [],
      /* 限制时间到今天 */
      pickerOptions:{
        disabledDate(time) {
            return time.getTime() > Date.now();
          },
      },
    };
  },
  methods: {
    /* 搜索 */
    onSubmit() {
      this.pages.pageNo = 1;
      this.pages.pageSize = 10;
      console.log(this.pages);
      this.getList();
    },
    /* 获取数据列表 */
    getList() {
      this.tabelLoading = true;
      getList(this.pages).then(res => {
          this.tabelLoading = false;
          this.tableData = res.data.rows;
          this.total=res.data.totalRows
      });
    },
    /* 分页大小 */
    handleSizeChange(val) {
      this.pages.pageNo = 1;//重置为第一页
      this.pages.pageSize = val;
      this.getList();
    },
    /* 分页当前页 */
    handleCurrentChange(val) {
      this.pages.pageNo = val;
      this.getList();
    },
    /* 重置搜索框 */
    resetInput() {
      this.$refs.multipleTable.clearSort();
      this.pages.pageNo=1;
      this.pages.pageSize=10;
      this.pages.name="";
      this.pages.projectDepartmentIds=[];
      this.getList();
    },
    /* 弹出框显隐 */
    DialogShow() {
      this.dialogVisible = true;
    },
    /* 新增 */
    addInfo() {
      this.dialogTitle = '新增';
      this.$refs.formRef.isRead = false;
      this.DialogShow();
    },
    /* 查看详情 */
    handleDetails(row) {
      this.dialogTitle = '查看详情';
      this.$refs.formRef.isRead = true;
      this.DialogShow();
      const obj = cloneDeep(row);
      obj.pictureId=[obj.pictureIds]
      delete obj.pictureIds;
      delete obj.isDelete;
      delete obj.projectDepartmentName;
      delete obj.tenantId;//剔除后端多余字段，改字段名
      /* 获取详情附件 */
      detail({id:obj.id}).then((res) => {
          obj.sysFileInfos=res.data.sysFileInfos;
          this.$refs.formRef.initFormDetail(obj); //调用子组件的方法时使表单回显
      })
    },
    /* 导出勾选框获取ID */
    handleSelectionChange(row) {
      this.ids = [];
      row.forEach(item => {
        this.ids.push(item.id);
      });
      this.isChoose = Boolean(this.ids.length);
    },
    /* 获取机构列表 */
    getDataRangByNature() {
      getDataRangByNature().then(res => {
          this.orgList = res.data;
      });
    },
    /* 操作回调 */
    handelSuccess(arr) {
      //arr[0]为返回列表第一页，否则为编辑，在当前页
      //arr[1]为是否是删除操作
      if (arr[0]) {
        this.pages.pageNo = 1;
      }
      if (arr[1]) {
        if(this.tableData.length===1&&this.pages.pageNo>1){
          this.pages.pageNo=this.pages.pageNo-1;//删除一页的最后一条时，跳转前一页（大于1页）
        }
      }
      this.getList();
      //刷新列表
    },
    /* 导出数据 */
    exportData() {
      this.exportLoading = true;
      exportlist({ ids:this.ids })
      .then(res => {
          exportFun(res);
          this.exportLoading = false;
          this.$message({
            type: 'success',
            message: '下载数据成功',
          })
            this.$refs.multipleTable.clearSelection();
        }).catch(() => {
            this.exportLoading = false;
            this.$refs.multipleTable.clearSelection();
          })
    }, 
  },
  created() {
    this.getList();
    this.getDataRangByNature();
  },
  watch:{
    trainDate(val){
      this.pages.searchBeginTime=val[0];
      this.pages.searchEndTime=val[1];
    },
  },
};
</script>
<style lang="less" scoped>
.btn {
  margin-left: 50px;
}
.exportbtn {
  float: right;
}
</style>
