export const validateRules = {
  data() {
    var validateOrgId = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择所属项目部'));
      }
      callback();
    };
    var validatePost = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入环水保设施名称'));
      }else if (value.length > 20) {
        return callback(new Error('最大字符为20'));
      }
      callback();
    };
    var validateProblem = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入所属工点'));
      } else if (value.length > 20) {
        return callback(new Error('最大字符为20'));
      }
      callback();
    };
    var validateSuggestion = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入设施投入量'));
      } else if (value.length > 20) {
        return callback(new Error('最大字符为20'));
      }
      callback();
    };
    var validateHiddenLevelId = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入单位'));
      }else if (value.length > 20) {
        return callback(new Error('最大字符为20'));
      }
      callback();
    };
    var validateHiddenTypeId = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入使用范围'));
      }else if (value.length > 20) {
        return callback(new Error('最大字符为20'));
      }
      callback();
    };
    var validateRemark = (rule, value, callback) => {
      if (value.length > 20) {
        return callback(new Error('最大字符为20'));
      }
      callback();
    };

    return {
      rules: {
        projectDepartment: [{ validator: validateOrgId, trigger: '' }],
        name: [{ validator: validatePost, trigger: '' }],
        location: [{ validator: validateProblem, trigger: '' }],
        num: [{ validator: validateSuggestion, trigger: '' }],
        unit: [{ validator: validateHiddenLevelId, trigger: '' }],
        scope: [{ validator: validateHiddenTypeId, trigger: '' }],
        remark: [{ validator: validateRemark, trigger: '' }],
      }
    };
  }
};
